import cl from "./AdSearch.module.scss"

const AdSearch = () => {
  return (
    <div className={cl["banner-wrap"]}>
        {/* Ad1 */}
      <div>
        <a className={cl["advertise"]} title="Contact LEIpapa for advertising" href="https://leipapa.com/advertising">Advertisement</a>
        <a href="https://go.leipapa.com/bitlocus" 
          title="Register on Bitlocus – Get the best conditions for your digital assets" 
          target="_blank" 
          rel="noreferrer noopener">
            <img 
              className={cl["banner"]} 
              src="/ads/bitlocus-leipapa.webp" 
              title="Register on Bitlocus – Get the best conditions for your digital assets" 
              alt="Register on Bitlocus – Get the best conditions for your digital assets">
            </img>
        </a>
        <a className={cl["advertise"]} title="Contact LEIpapa for advertising" href="https://leipapa.com/advertising">Advertise on LEIpapa</a>
      </div>
    </div>
  );
}

export default AdSearch;
