// src/components/functional/Prices/statesUsData.js

export const statesUs = [
        { value: "US-AL", label: "Alabama (US-AL)", price: 59, surcharge: 0 },
        { value: "US-AK", label: "Alaska (US-AK)", price: 59, surcharge: 0 },
        { value: "US-AZ", label: "Arizona (US-AZ)", price: 59, surcharge: 0 },
        { value: "US-AR", label: "Arkansas (US-AR)", price: 59, surcharge: 0 },
        { value: "US-CA", label: "California (US-CA)", price: 59, surcharge: 0 },
        { value: "US-CO", label: "Colorado (US-CO)", price: 59, surcharge: 0 },
        { value: "US-CT", label: "Connecticut (US-CT)", price: 59, surcharge: 0 },
        { value: "US-DE", label: "Delaware (US-DE)", price: 59, surcharge: 5 },
        { value: "US-DC", label: "District of Columbia (US-DC)", price: 59, surcharge: 0 },
        { value: "US-FL", label: "Florida (US-FL)", price: 59, surcharge: 0 },
        { value: "US-GA", label: "Georgia (US-GA)", price: 59, surcharge: 0 },
        { value: "US-HI", label: "Hawaii (US-HI)", price: 59, surcharge: 0 },
        { value: "US-ID", label: "Idaho (US-ID)", price: 59, surcharge: 0 },
        { value: "US-IL", label: "Illinois (US-IL)", price: 59, surcharge: 0 },
        { value: "US-IN", label: "Indiana (US-IN)", price: 59, surcharge: 0 },
        { value: "US-IA", label: "Iowa (US-IA)", price: 59, surcharge: 0 },
        { value: "US-KS", label: "Kansas (US-KS)", price: 59, surcharge: 0 },
        { value: "US-KY", label: "Kentucky (US-KY)", price: 59, surcharge: 0 },
        { value: "US-LA", label: "Louisiana (US-LA)", price: 59, surcharge: 0 },
        { value: "US-ME", label: "Maine (US-ME)", price: 59, surcharge: 0 },
        { value: "US-MD", label: "Maryland (US-MD)", price: 59, surcharge: 0 },
        { value: "US-MA", label: "Massachusetts (US-MA)", price: 59, surcharge: 0 },
        { value: "US-MI", label: "Michigan (US-MI)", price: 59, surcharge: 0 },
        { value: "US-MN", label: "Minnesota (US-MN)", price: 59, surcharge: 0 },
        { value: "US-MS", label: "Mississippi (US-MS)", price: 59, surcharge: 0 },
        { value: "US-MO", label: "Missouri (US-MO)", price: 59, surcharge: 0 },
        { value: "US-MT", label: "Montana (US-MT)", price: 59, surcharge: 0 },
        { value: "US-NE", label: "Nebraska (US-NE)", price: 59, surcharge: 0 },
        { value: "US-NV", label: "Nevada (US-NV)", price: 59, surcharge: 0 },
        { value: "US-NH", label: "New Hampshire (US-NH)", price: 59, surcharge: 0 },
        { value: "US-NJ", label: "New Jersey (US-NJ)", price: 59, surcharge: 0 },
        { value: "US-NM", label: "New Mexico (US-NM)", price: 59, surcharge: 0 },
        { value: "US-NY", label: "New York (US-NY)", price: 59, surcharge: 0 },
        { value: "US-NC", label: "North Carolina (US-NC)", price: 59, surcharge: 0 },
        { value: "US-ND", label: "North Dakota (US-ND)", price: 59, surcharge: 0 },
        { value: "US-OH", label: "Ohio (US-OH)", price: 59, surcharge: 0 },
        { value: "US-OK", label: "Oklahoma (US-OK)", price: 59, surcharge: 0 },
        { value: "US-OR", label: "Oregon (US-OR)", price: 59, surcharge: 0 },
        { value: "US-PA", label: "Pennsylvania (US-PA)", price: 59, surcharge: 0 },
        { value: "US-RI", label: "Rhode Island (US-RI)", price: 59, surcharge: 0 },
        { value: "US-SC", label: "South Carolina (US-SC)", price: 59, surcharge: 0 },
        { value: "US-SD", label: "South Dakota (US-SD)", price: 59, surcharge: 0 },
        { value: "US-TN", label: "Tennessee (US-TN)", price: 59, surcharge: 0 },
        { value: "US-TX", label: "Texas (US-TX)", price: 59, surcharge: 0 },
        { value: "US-UT", label: "Utah (US-UT)", price: 59, surcharge: 0 },
        { value: "US-VT", label: "Vermont (US-VT)", price: 59, surcharge: 0 },
        { value: "US-VA", label: "Virginia (US-VA)", price: 59, surcharge: 0 },
        { value: "US-WA", label: "Washington (US-WA)", price: 59, surcharge: 0 },
        { value: "US-WV", label: "West Virginia (US-WV)", price: 59, surcharge: 0 },
        { value: "US-WI", label: "Wisconsin (US-WI)", price: 59, surcharge: 0 },
        { value: "US-WY", label: "Wyoming (US-WY)", price: 59, surcharge: 0 }
    ];