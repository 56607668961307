import React, { useCallback, useEffect, useMemo, useState } from "react";
import Container from "../../components/ui/ui kit/Container";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import {
  TransferLeiPage,
  Title,
  Hr,
  TabName,
  NavLink,
} from "./style";
import "./table_style.css"
import Input from "../../components/ui/ui kit/Input";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import cl from "./TransferLei.module.sass";
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import Loader from "../../components/ui/Loader";
// Import Prices
import { countries } from '../../components/functional/Prices/countriesData';
import { statesUs } from '../../components/functional/Prices/statesUsData';
import { statesCanada } from '../../components/functional/Prices/statesCanadaData';
import { statesAE } from "../../components/functional/Prices/statesAEData";
import { calculateMultiYearPrice } from '../../components/functional/Prices/multiYearPrice';
import {
  calculateEntityTypeSurcharge,
  calculateLevel2Surcharge
} from '../../components/functional/Prices/surchargeUtils.js';
import { Helmet } from "react-helmet";
import { SearchLeiCode } from "../../api/services/search-lei";
import { CreateLeiService } from "../../api/services/create-lei";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { Modal, Button } from 'flowbite-react';
// Datatable
// import BootstrapTable from "react-bootstrap-table-next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const defaultCompanyList = [{
  companyName: "",
  companyNumber: "",
  leiNumber: "",
  entityType: "",
  status: "",
  regStatus: "",
  id: "",
  legalJurisdiction: "",
  managingLou: "",
}];

const defaultCompanyData = {
  company_name: "",
  company_number: "",
  lei_number: "",
  entity_type: "",
  status: "",
  reg_status: "",
  multi_year_support: 0,
  reporting_exception_reason: "",
  is_level_2_data_available: false,
};

const NON_PUBLIC_USE_CASES = [
  "Obstacles in the laws or regulations of a jurisdiction prevent the child Legal Entity from providing or publishing this information.",
  "The child Legal Entity reports that the consent of the parent was necessary under the applicable legal framework and the parent did not consent or could not be contacted.",
  "Binding legal commitments such as the articles governing the child Legal Entity, or a contract prevent the child Legal Entity from providing or publishing this information. This is distinct from laws or regulations of a jurisdiction that prevent the child Legal Entity from providing or publishing information on its parent.",
  "The child Legal Entity has sought to consult the parent entity about the reporting of the parent information to the Global LEI System but could not confirm the absence of detriment in a way that can appropriately prevent liability risks for the child Legal Entity (or those acting on its behalf) under the applicable legal framework. The disclosure of this information would be detrimental to the child Legal Entity or the relevant parent.",
  "The disclosure of this information would be detrimental to the Legal Entity or the relevant parent. This will include reasons generally accepted by public authorities in similar circumstances, based on a declaration by the entity.",
];

export const TransferLei = () => {
  const isAuth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  //const [countries, setCountries] = useState<string[]>([]);

  const [selectedCountry, setSelectedCountry] = useState('');
  
  // States
  const [selectedState, setSelectedState] = useState('');
  const [showStates, setShowStates] = useState(false);
  const [stateOptions, setStateOptions] = useState([] as any[]); // Set initial state as an empty array

  const [isItemLoading, setIsItemLoading] = useState(false);
  const [selectedLei, setSelectedLei] = useState("");

  // Company data stored in Session
  const [companyName, setCompanyName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [entityType, setEntityType] = useState("GENERAL");
  const [multiYearSupport, setMultiYearSupport] = useState(1);
  const [surchargePrice, setSurchargePrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [isLevel2DataAvailable, setIsLevel2DataAvailable] = useState<boolean>(false);

  const [orderType, setOrderType] = useState("Transfer");
  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState(defaultCompanyData);
  const [companyList, setCompanyList] = useState(defaultCompanyList);
  const [reportingExceptionReason, setReportingExceptionReason] = useState("");

  const [Q1, setQ1] = React.useState("");
  const [Q2, setQ2] = React.useState("");
  const [Q3, setQ3] = React.useState("");
  const [Q4, setQ4] = React.useState("");
  const [npUseCaseConfirmed, setNpUseCaseConfirmed] = React.useState(false);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [mergedOrRetiredLeiComments, setMergedOrRetiredLeiComments] = useState("");

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  let multiYearPrice = calculateMultiYearPrice(multiYearSupport);
  let entityTypeSurcharge = calculateEntityTypeSurcharge(
    companyData && companyData.entity_type ? companyData.entity_type : entityType
  );
  let level2Surcharge = calculateLevel2Surcharge(isLevel2DataAvailable);

  const handlePresearch = () => {
    const company_data = {
      "company_name": companyName,
      "country_code": countryCode,
    }
  
    if (companyName === "" && countryCode === "") {
      alert("Please enter a Company Name or an LEI Number, and choose the Country. If you transfer an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (companyName !== "" && countryCode === "") {
      alert("Please choose the Country. If you transfer an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (
      companyName !== "" &&
      (countryCode === "US" || countryCode === "CA" || countryCode === "AE") &&
      selectedState === ""
    ) {
      alert("Please choose the State as well. If you transfer an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else if (companyName === "" && countryCode !== "") {
      alert("Please enter a Company Name or an LEI Number. If you transfer an LEI for a Fund or similar category please select the required Entity Type.");
      setIsSearchClicked(false);
    } else {
      setIsSearchClicked(true);
      getCompanyList(company_data);
    }
  };
  
  //*********** States *************
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setCountryCode(selectedCountry);

    if (selectedCountry === 'US') {
      setShowStates(true);
      setStateOptions(statesUs);
      setSelectedState('');
    } else if (selectedCountry === 'CA') {
      setShowStates(true);
      setStateOptions(statesCanada);
      setSelectedState('');
    } else if (selectedCountry === 'AE') {
      setShowStates(true);
      setStateOptions(statesAE);
      setSelectedState('');
    } else {
      setShowStates(false);
      setSelectedState('');
    }
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = event.target.value;
    // setSelectedCountry(selectedCountry);
    setSelectedState(selectedState);
    setCountryCode(selectedState); 
  };

  //*********** States End *************
  
  // const requestCountries = useCallback(async () => {
  //   try {
  //     // const res = await CreateLeiService.requestCountries();
  //     // setCountries(
  //     //   Object.keys(res.data.data).map((el) => el + " - " + res.data.data[el])
  //     // );
  //     await CreateLeiService.requestCountries();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  const getCompanyList = useCallback(async (
    companyDataPresearch: {
      company_name: string,
      country_code: string
    }
  ) => {
    try {
      setCompanyList([]);
      setCompanyData(defaultCompanyData);
      setLoading(true);
      const [res1, res2] = await Promise.all([
        SearchLeiCode.searchLeiByCompanyName(companyDataPresearch.company_name, companyDataPresearch.country_code),
        SearchLeiCode.searchLeiByLei(companyDataPresearch.company_name, companyDataPresearch.country_code),
      ]);
      const data = [
        ...res1.data.data,
        ...res2.data.data,
      ].map(item => {
        const companyNumber = item.attributes.entity.registeredAs;
        let entityType = item.attributes.entity.category;

        if (!companyNumber && entityType !== 'FUND') {
          entityType = 'OTHER';
        }

        return {
          id: item.id,
          companyName: item.attributes.entity.legalName.name,
          companyNumber,
          leiNumber: item.attributes.lei,
          entityType,
          status: item.attributes.entity.status,
          regStatus: item.attributes.registration.status,
          legalJurisdiction: item.attributes.entity.jurisdiction,
          managingLou: item.attributes.registration.managingLou,
        }
      });

      setCompanyList(data);
    } catch (error) {
      console.log('-> ERROR in getCompanyList:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  //Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleOnQ1Change = (q1Value: any) => {
    if (Q1 !== q1Value) {
      setQ1(q1Value);
      setQ2("");
      setQ3("");
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q1Value === "yes" ? "NATURAL_PERSONS" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ2Change = (q2Value: any) => {
    if (Q2 !== q2Value) {
      setQ2(q2Value);
      setQ3("");
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q2Value === "no" ? "NO_KNOWN_PERSON" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ3Change = (q3Value: any) => {
    if (Q3 !== q3Value) {
      setQ3(q3Value);
      setQ4("");
      setIsLevel2DataAvailable(false);
      setReportingExceptionReason(q3Value === "no" ? "NON_CONSOLIDATING" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const handleOnQ4Change = (q4Value: any) => {
    if (Q4 !== q4Value) {
      setQ4(q4Value);
      setIsLevel2DataAvailable(q4Value === "yes");
      console.log("q4Value:", q4Value, q4Value === "yes");
      setReportingExceptionReason(q4Value === "no" ? "NON_PUBLIC" : "");
      setNpUseCaseConfirmed(false);
    }
  }

  const l2Message = useMemo(() => {
    if (reportingExceptionReason === "" && isLevel2DataAvailable) {
      return "You need to provide Level 2 Data for this entity. Don't worry, once you have placed an order, we will contact you to clarify the details of the ownership structure. The fee (€30) for Level 2 Data processing will be added to your order.";
    } else if (reportingExceptionReason === "NON_PUBLIC") {
      return "Both your Direct and Ultimate Parent Information will have NON_PUBLIC as the exception reason. Use this NON_PUBLIC exception reason ONLY if one of the following use cases applies to the Legal Entity!";
    } else {
      return "";
    }
  }, [isLevel2DataAvailable, reportingExceptionReason]);

  // useEffect(() => {
  //   requestCountries();
  // }, [requestCountries]);

  useEffect(() => {
    let updatedPrice: number
    let updatedSurchargePrice: number

    if (selectedCountry === "CA" || selectedCountry === "US" || selectedCountry === "AE") {
      const selectedState = stateOptions.find(state => state.value === countryCode);
      updatedPrice = selectedState
        ? multiYearSupport * (selectedState.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedState ? multiYearSupport * selectedState.surcharge: 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    } else {
      const selectedCountry = countries.find(country => country.value === countryCode);
      updatedPrice = selectedCountry
        ? multiYearSupport * (selectedCountry.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedCountry
        ? multiYearSupport * selectedCountry.surcharge
        : 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    }

    console.log('---> price:', updatedPrice);

    setCompanyData(companyData => ({
      ...companyData,
      multi_year_support: multiYearSupport,
      reporting_exception_reason: reportingExceptionReason,
      price: updatedPrice,
      multi_year_price: multiYearPrice,
      entity_type_surcharge: entityTypeSurcharge,
      level2_surcharge: level2Surcharge,
      surcharge_price: updatedSurchargePrice,
      is_level_2_data_available: isLevel2DataAvailable,
      merged_or_retired_lei_comments: mergedOrRetiredLeiComments.trim()
    }));
  }, [
    countryCode, entityTypeSurcharge, isLevel2DataAvailable, level2Surcharge,
    multiYearPrice, multiYearSupport, reportingExceptionReason,
    selectedCountry, stateOptions, mergedOrRetiredLeiComments
  ]);

  useEffect(() => {
    if (
      "company_name" in companyData
      && "lei_number" in companyData
      && "company_number" in companyData
      && "entity_type" in companyData
      && "multi_year_support" in companyData
      && ("reporting_exception_reason" in companyData || isLevel2DataAvailable)
    ) {
      if (
        companyData["company_name"]
        && companyData["lei_number"]
        && companyData["company_number"] !== "-"
        && companyData["entity_type"] !== "-"
        && companyData["multi_year_support"]
        && (companyData["reporting_exception_reason"] || isLevel2DataAvailable)
        && (companyData["reporting_exception_reason"] !== "NON_PUBLIC" || npUseCaseConfirmed)
        && (!["RETIRED", "MERGED"].includes(companyData["reg_status"]) || mergedOrRetiredLeiComments.trim())
      ) {
        setNextButtonEnabled(true);
        console.log("true");
      } else {
        setNextButtonEnabled(false);
      }
    } else {
      setNextButtonEnabled(false);
    }
    console.log("companyData", companyData)
  }, [companyData, isLevel2DataAvailable, mergedOrRetiredLeiComments, npUseCaseConfirmed]);

  const cancelItemLoading = () => {
    setIsItemLoading(false);
    setSelectedLei("");

    setCompanyList(companyList => companyList.map(item => ({
      ...item,
      id: item.leiNumber,
    })));
  };

  const checkCompany = async (row: any) => {
    setSelectedLei(row.leiNumber);
    setIsItemLoading(true);

    setCompanyList(companyList => companyList.map(item => ({
      ...item,
      id: item.leiNumber + "Loading",
    })));

    try {
      const isRAToRATransfer = row.managingLou === "529900T8BM49AURSDO55";

      const res = await CreateLeiService.orderSubmitStatus({
        order_type: isRAToRATransfer ? 'RA-to-RA Transfer' : 'Transfer',
        company_name: row['companyName'],
        company_number: row['companyNumber'],
        lei_number: row['leiNumber'],
        legal_jurisdiction: countryCode
      })

      const submitStatus = res.data.data['status'];

      if (submitStatus !== 'NO_ACTIVE_ORDERS') {
        const existingCompanyName = row["companyName"];

        const alertMessage = submitStatus !== 'ALREADY_REGISTERED'
          ? `There are active orders for company '${existingCompanyName}', please select another company.`
          : isRAToRATransfer
            ? `This LEI is already managed by the LEI issuer Ubisecure RapidLEI and is under the service of LEIpapa. You don't need to transfer it. Please contact us via info@leipapa.com if you don't have access to this LEI in the Records section of your Account.`
            : `LEI already transferred for company '${existingCompanyName}', please select another company.`;

        alert(alertMessage);
        setCompanyData(defaultCompanyData);
        cancelItemLoading();
        return;
      } else {
        if (isRAToRATransfer) {
          setIsModalVisible(true);
          return;
        }

        const data = {
          company_name: row.companyName,
          company_number: row.companyNumber,
          lei_number: row.leiNumber,
          entity_type: row.entityType,
          status: row.status,
          reg_status: row.regStatus,
          managing_lou: row.managingLou,
          multi_year_support: companyData["multi_year_support"],
          reporting_exception_reason: companyData["reporting_exception_reason"],
          is_level_2_data_available: Boolean(companyData["is_level_2_data_available"]),
        };
        setEntityType(row.entityType);
        setCompanyData(data);
        setOrderType('Transfer');

        setIsItemLoading(false);
        setSelectedLei("");

        setCompanyList([{
          ...row,
          id: row.id + "s",
        }]);

        return;
      }
    } catch (e) {
      console.log('-> ERROR in checkCompany:', e);
      cancelItemLoading();
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    cancelItemLoading();
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    const row = companyList.find(item => item.leiNumber === selectedLei);

    if (!row) {
      cancelItemLoading();
      return;
    }

    const data = {
      company_name: row.companyName,
      company_number: row.companyNumber,
      lei_number: row.leiNumber,
      entity_type: row.entityType,
      status: row.status,
      reg_status: row.regStatus,
      managing_lou: row.managingLou,
      multi_year_support: companyData["multi_year_support"],
      reporting_exception_reason: companyData["reporting_exception_reason"],
      is_level_2_data_available: Boolean(companyData["is_level_2_data_available"]),
    };
    setEntityType(row.entityType);
    setCompanyData(data);
    setOrderType('RA-to-RA Transfer');

    setIsItemLoading(false);
    setSelectedLei("");

    setCompanyList([{
      ...row,
      id: row.id + "s",
    }]);
  };

  const selectCompany = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
    let isRowSelected = false;
    if (companyData && companyData['company_name'] && companyData['lei_number']) {
      isRowSelected = companyData['company_name'] === row['companyName'] &&
        companyData['lei_number'] === row['leiNumber'];
    }

    let isCheckingStatus = false;
    if (isItemLoading && selectedLei && selectedLei === row['leiNumber']) {
      isCheckingStatus = true;
    } 

    return (
      <div>
        {isCheckingStatus && <div className={cl["item-loading-wrapper"]}>
          <LoadingIcon />
        </div>}
        {!isCheckingStatus && <button 
          className={`${cl["button_select"]} ${isRowSelected ? cl['button_select_selected'] : ''}`}
          onClick={() => {
            if (!isRowSelected && !row.id.endsWith("Loading")) {
              checkCompany(row);
            }
          }}
        >
          {isRowSelected ? 'Selected' : 'Select'}
        </button>}
      </div>
    );
  };

  const columns = [
    {
      dataField: "companyNameLei",
      text: "Company",
      headerAlign: "center",
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <div>
            {row.leiNumber}<br/>{row.companyName}
          </div>
        );
      }
    },
    {
      dataField: "companyNumber",
      text: "Company Number",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "legalJurisdiction",
      text: "Jurisdiction", 
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "status",
      text: "Entity Legal Status", 
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "regStatus",
      text: "Reg Status", 
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "selectCompany",
      text: "",
      formatter: selectCompany,
    }
  ];

  const handleNext = async () => {
    const data = companyData;
    setLoading(true);
    const preparedData = {
      ...data,
      legal_jurisdiction: countryCode,
      order_type: orderType,
    };
    console.log("data", preparedData);
    await dispatch(fetchDataForm(preparedData))

    navigate('/relationship', {});
  };

  const isCompanySelected = useMemo(() => {
    return !loading && isSearchClicked && companyData && companyData.lei_number;
  }, [companyData, isSearchClicked, loading]);

  return (
    <Container>
      <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI Transfer – Entity data</title>
        <meta 
          name="description" 
          content="LEI Transfer Process – Entity data. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <TransferLeiPage>
          <div className={cl["container_big"]}>
            <div className={cl["title_wrap"]}>
              <Title>LEI Transfer – Entity data</Title>
            </div>

            <TabName className={cl["tab_name"]}>
              Let's locate Legal Entity details and the LEI code <span className={cl["tooltip"]} data-tooltip="Please enter the officially registered Legal Entity name (or the LEI code) of the company you want to transfer, the jurisdiction of incorporation, and the Entity type.">ℹ️</span>
            </TabName>
            <div className={cl["card_body"]}>
              <div className={cl["input_wrap"]}>
                <div>
                  <Input
                    placeholder="Company Name or LEI Code"
                    uiProps={{width: "100%", margin: "0px"}}
                    {...{
                      onChange:(data) => 
                      setCompanyName(data.target.value),
                    }}
                    isRequired
                  />
                </div>
                <div>
                  <label htmlFor="country">Country<span style={{color: 'red'}}> *</span></label>
                  <select id="country" className={cl["custom-select"]}
                    placeholder="Country"
                    defaultValue=""
                    onChange={handleCountryChange}
                  >
                    <option disabled value="">
                      Choose Country
                    </option>
                    {countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>

                  {showStates && (
                    <select required id="state" className={cl["custom-select"]}
                      placeholder="State"
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      <option disabled value="">
                        Select State
                      </option>
                      {stateOptions.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div>
                  <label htmlFor="entitytype">Entity Type <span className={cl["tooltip"]} data-tooltip="You need to click the Search button each time you change the value of this Entity Type field. ">ℹ️</span></label>
                  <select id="entitytype" className={cl["custom-select"]}
                    value={entityType}
                    onChange={(event) => setEntityType(event.target.value)}
                  >
                    {["GENERAL", "SOLE_PROPRIETOR", "FUND", "WILL_PENSION", "TRUST", "OTHER"].map((variant) => (
                      <option key={variant} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </select>

                </div>
                <button className={cl["button_search"]} onClick={handlePresearch}>
                  <span>Search</span>
                </button>
              </div>

              {isSearchClicked &&
                (
                  <>
                    <div className={cl["table_wrap"]}>
                      {loading ? <Loader /> : ""}
                      {companyList.length > 0 && (
                        <>
                          <DataTable value={companyList} className={cl["table"]}>
                            {columns.map((col, colIndex) => (
                                <Column 
                                    key={col.dataField}
                                    field={col.dataField}
                                    header={col.text}
                                    body={col.formatter ? (rowData) => col.formatter(rowData[col.dataField], rowData, colIndex, "") : undefined}
                                    alignHeader={colIndex === 0 ? "left" : "center"}
                                    style={{ padding: '10px', textAlign: col.align as 'left' | 'center' | 'center' | 'center' }}
                                    // style={{ textAlign: col.align as 'left' | 'right' | 'center' }}
                                    // headerStyle={{ textAlign: col.headerAlign as 'left' | 'right' | 'center' }}
                                />
                            ))}
                          </DataTable>
                          {/* <BootstrapTable
                            id={cl["table"]}
                            keyField="leiNumber"
                            data={companyList}
                            columns={columns}
                          />   */}
                          {/* {companyStatus && <span className={cl["company_status"]}>{companyStatus}</span>} */}
                        </>
                      )}
                      {!loading && companyList.length === 0 && "No companies that meet the search criteria."}
                    </div>

                    {!isCompanySelected && !loading && (
                      <div className={cl["wrap_l2"]}>
                        <p style={{ lineHeight: "1.5" }}>The search results are provided for the {countryCode} jurisdiction.</p><br />
                        <p style={{ lineHeight: "1.5" }}>Not the results you expected? If your Company Name consists of more than one word, try writing the full Company Name for a more accurate query.</p>
                      </div>
                    )}

                    {isCompanySelected && companyData.reg_status && ['RETIRED', 'MERGED'].includes(companyData.reg_status) && (
                      <div className={cl["input-merged-wrapper"]}>
                        <label htmlFor="mergedOrRetiredLeiComments">
                          The selected LEI is {companyData.reg_status}. Please leave a comment why the LEI should be transferred.
                          <span style={{color: 'red'}}> *</span>
                        </label>
                        <textarea
                          className={cl["textarea_custom"]}
                          id="mergedOrRetiredLeiComments"
                          placeholder="Max 400 characters"
                          maxLength={400}
                          value={mergedOrRetiredLeiComments}
                          onChange={e => {
                            setMergedOrRetiredLeiComments(e.target.value || "")
                          }}
                        ></textarea>
                      </div>
                    )}

                    {isCompanySelected && (
                      <>
                        <div className={cl["input_wrap_years"]}>
                          <div>
                            <label htmlFor="multiyearsupport">Year to support <span className={cl["tooltip"]} data-tooltip="Specify for how many years you wish to register the LEI code.">ℹ️</span></label>
                            <select id="multiyearsupport" className={cl["custom-select"]}
                              value={multiYearSupport}
                              onChange={(event) => setMultiYearSupport(Number(event.target.value))}
                            >
                              <option value="0" disabled>
                                Years to Support
                              </option>
                              {["1", "2", "3", "4", "5"].map((variant) => (
                                <option key={variant} value={variant}>
                                  {variant}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
    
                        <div className={cl["wrap_l2"]}>
                          <div className={cl["Q"]}>
                            <p>{"Is the Legal Entity controlled by only Private Individuals (Natural Persons)?"}</p>
                            <button className={(Q1 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ1Change("yes")}>Yes</button>
                            <button className={(Q1 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ1Change("no")}>No</button>  
                          </div>
                          {Q1 === "no" && (
                            <div className={cl["Q"]}>
                              <p>{"Is the Legal Entity owned by another legal entity? (Controlling share, typically > 50% shareholding)"}</p>
                              <button className={(Q2 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ2Change("yes")}>Yes</button>
                              <button className={(Q2 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ2Change("no")}>No</button>  
                            </div>
                          )}
                          {(Q2 === "yes") && (
                            <div className={cl["Q"]}>
                              <p>{"Does the Legal Entity consolidate financial statements into the controlling legal entity?"}</p>
                              <button className={(Q3 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ3Change("yes")}>Yes</button>
                              <button className={(Q3 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ3Change("no")}>No</button>  
                            </div>
                          )}
                          {(Q3 === "yes") && (
                            <div className={cl["Q"]}>
                              <p>
                                Are you able to make the Parental information for either your&nbsp;
                                <a
                                  href="https://www.gleif.org/en/lei-data/access-and-use-lei-data/level-2-data-who-owns-whom"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Direct or Ultimate Parent
                                </a>&nbsp;Public?&nbsp;
                                <span
                                  className={cl["tooltip"]}
                                  data-tooltip="Any company where your accounts are directly consolidated is known as your Direct Parent. A higher-level company that consolidates the accounts for the group would be an Ultimate Parent. (Note that they can be the same legal entity)"
                                >
                                  ℹ️
                                </span>
                              </p>
                              <button className={(Q4 === "yes") ? cl["button_yes_clicked"] : cl["button_yes"]} onClick={() => handleOnQ4Change("yes")}>Yes</button>
                              <button className={(Q4 === "no") ? cl["button_no_clicked"] : cl["button_no"]} onClick={() => handleOnQ4Change("no")}>No</button>  
                            </div>
                          )}
                          {l2Message && <div className={cl["l2_message"]}>{l2Message}</div>}
                          {reportingExceptionReason === "NON_PUBLIC" && (
                            <div className={cl["use-case-checkbox-wrapper"]}>
                              <input
                                id={`use-case-confirmed`}
                                type="checkbox"
                                name="useCaseConfirmed"
                                checked={npUseCaseConfirmed}
                                onChange={(e) => {
                                  setNpUseCaseConfirmed(e.target.checked);
                                }}
                              />
                              <label htmlFor={`use-case-confirmed`}>
                                <p><span style={{fontWeight: 'bold'}}>I confirm that at least one of the below cases is applicable to the Legal Entity I am applying for, and that is the reason for choosing the NON_PUBLIC exception reason.</span></p>
                                {/* Non Public: For all of the below use cases: */}
                              </label>
                            </div>
                          )}
                          {reportingExceptionReason === "NON_PUBLIC" && (
                            <ul className={cl["use-cases-wrapper"]}>
                              {NON_PUBLIC_USE_CASES.map((item, index) => (
                                <li key={`use-case-${index}`}>
                                  { item }
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
    
                        <Hr />
                        <div className={cl["wrap_l2_right"]}>
                          <div className={cl["price_text"]}>Order calculation</div>
                          <p>Years to support: {multiYearSupport}</p>
                          <p>Base price: €{multiYearPrice} x {multiYearSupport} = {multiYearPrice * multiYearSupport}</p>
                          {surchargePrice > 0 && (
                            <p>Jurisdictional surcharge: €{surchargePrice/multiYearSupport} x {multiYearSupport} = {surchargePrice}</p>
                          )}
                          {entityTypeSurcharge > 0 && (
                            <p>Entity type surcharge: €{entityTypeSurcharge} x {multiYearSupport} = {entityTypeSurcharge * multiYearSupport}</p>
                          )}
                          {level2Surcharge > 0 && (
                            <p>Level 2 surcharge: €{level2Surcharge} x {multiYearSupport} = {level2Surcharge * multiYearSupport}</p>
                          )}
                          <p>Total: €{price}</p>
                          {/* <p>Total: €{multiYearPrice * multiYearSupport + surchargePrice + entityTypeSurcharge + level2Surcharge}</p> */}
                          {/* <span className={cl["price_text"]}>Level 2 surcharge: €{level2Surcharge}</span> */}
                        </div>
                      </>
                    )}
                  </>
                )
              }
            </div>
            <Hr/>
            <div className={cl["title_inner-button"]}>
              <NavLink to="/">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>

              <button className={cl["button_next"]} onClick={handleNext} disabled={!nextButtonEnabled}>
                <NextIcon />
                <span className={cl["button_text"]}>Next</span>
              </button>
            </div>
          </div>
        </TransferLeiPage>
      )}
      <Modal show={isModalVisible} onClose={handleModalCancel}>
        <Modal.Header>RA-to-RA Transfer Confirmation</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base">
              This LEI {selectedLei} is already managed by the LEI issuer Ubisecure RapidLEI and is under the service of another Registration Agent. The transfer will be made from your current Registration Agent to LEIpapa. The LEI issuer will not be changed. 
              <br/><br/>
              IMPORTANT! You are required to download and sign{" "}
              <a
                href="https://leipapa.com/loa/Letter-of-Authorisation-template-RA-Internal-transfers.docx"
                className={cl["modal-link"]}
              >
                LoA Transfer Request
              </a> form, and upload the signed file on the next page as a Letter of Authorization.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalConfirm}>Confirm</Button>
          <Button color="gray" onClick={handleModalCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
