// src/components/functional/Prices/statesAEData.js

export const statesAE = [
    { value: "AE", label: "Federal (AE)", price: 59, surcharge: 0 },
    { value: "AE-AZ", label: "Abu Dhabi (AE-AZ)", price: 59, surcharge: 0 },
    { value: "AE-AJ", label: "Ajman (AE-AJ)", price: 59, surcharge: 0 },
    { value: "AE-DU", label: "Dubai (AE-DU)", price: 59, surcharge: 0 },
    { value: "AE-FU", label: "Fujairah (AE-FU)", price: 59, surcharge: 0 },
    { value: "AE-RK", label: "Ras Al Khaimah (AE-FU)", price: 59, surcharge: 0 },
    { value: "AE-SH", label: "Sharjah (AE-SH)", price: 59, surcharge: 0 },
    { value: "AE-UQ", label: "Umm Al Quwain (AE-UQ)", price: 59, surcharge: 0 }
];