export function calculateMultiYearPrice(multiYearSupport) {
    let multiYearPrice = 0;
    // Calculate multiYearPrice based on multiYearSupport
    switch (multiYearSupport) {
      case 1:
        multiYearPrice = 59;
        break;
      case 2:
        multiYearPrice = 57;
        break;
      case 3:
        multiYearPrice = 55;
        break;
      case 4:
        multiYearPrice = 53;
        break;
      case 5:
        multiYearPrice = 50;
        break;
      default:
        multiYearPrice = 0; // Set a default value if multiYearSupport is not within the specified range
    }
    return multiYearPrice;
  }
  