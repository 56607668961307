import React, { useEffect, useMemo, useState } from "react";
import Container from "../../components/ui/ui kit/Container";
import { ReactComponent as NextIcon } from "../../assets/icons/home/createLei/next.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/home/createLei/cancel.svg";
import {
  RenewLeiPage,
  Title,
  TabName,
  Hr,
  NavLink,
} from "./style";
import "./table_style.css"
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import cl from "./RenewLei.module.sass";
import { useDispatch } from "react-redux";
import { fetchDataForm } from "../../redux/action-creators/dataFormReducer";
import Loader from "../../components/ui/Loader";
// Import Prices
import { countries } from '../../components/functional/Prices/countriesData';
import { calculateMultiYearPrice } from '../../components/functional/Prices/multiYearPrice';
import {
  calculateEntityTypeSurcharge,
  calculateLevel2Surcharge
} from '../../components/functional/Prices/surchargeUtils.js';
import { Helmet } from "react-helmet";
import { CreateLeiService } from "../../api/services/create-lei";
import { statesCanada } from "../../components/functional/Prices/statesCanadaData";
import { statesUs } from "../../components/functional/Prices/statesUsData";
import { statesAE } from "../../components/functional/Prices/statesAEData";
import { joinClasses } from "../../utils/joinClasses";
// DataTable
// import BootstrapTable from "react-bootstrap-table-next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const defaultCompanyList = [{
  id: "",
  company_name: "",
  company_number: "",
  lei_number: "",
  entity_type: "",
  reporting_exception_reason: "",
  is_level_2_data_available: "",
  legal_jurisdiction: false,
  sa_first_name: "",
  sa_last_name: "",
  sa_email: "",
  sa_phone: "",
  next_renewal_date: "",
  registration_status: "",
  can_be_renewed: false,
}];

const defaultCompanyData = {
  company_name: "",
  company_number: "",
  lei_number: "",
  entity_type: "",
  reporting_exception_reason: "",
  is_level_2_data_available: false,
  legal_jurisdiction: "",
  sa_first_name: "",
  sa_last_name: "",
  sa_email: "",
  sa_phone: "",
  next_renewal_date: "",
  registration_status: "",
  can_be_renewed: false,
  multi_year_support: 0,
};

export const RenewLei = () => {
  const isAuth = useAuth();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  
  // Company data stored in Session
  const [multiYearSupport, setMultiYearSupport] = useState(1);
  const [surchargePrice, setSurchargePrice] = useState(0);
  const [price, setPrice] = useState(0);

  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState(defaultCompanyData);
  const [companyList, setCompanyList] = useState(defaultCompanyList);
  const [isLevel1DataSame, setIsLevel1DataSame] = useState(true);
  const [userComments, setUserComments] = React.useState("");

  const navigate = useNavigate();

  const isLevel2DataAvailable = companyData && companyData.lei_number && companyData.is_level_2_data_available;

  let multiYearPrice = calculateMultiYearPrice(multiYearSupport);
  let entityTypeSurcharge = calculateEntityTypeSurcharge(
    companyData && companyData.entity_type ? companyData.entity_type : "GENERAL"
  );
  let level2Surcharge = calculateLevel2Surcharge(isLevel2DataAvailable);
  const countryCode = companyData ? companyData.legal_jurisdiction : "";

  useEffect(() => {
    const getCompanyList = async () => {
      try {
        setCompanyList([]);
        setCompanyData(defaultCompanyData);
        setLoading(true);
        const res = await CreateLeiService.requestLeiList();

        const data = res.data.map((item: any) => ({
          id: item.lei_number,
          ...item,
          // can_be_renewed: true,
        }));
  
        setCompanyList(data);
      } catch (error) {
        console.log('-> ERROR in getCompanyList:', error);
      } finally {
        setLoading(false);
      }
    }
    
    getCompanyList();
  }, []);

  //Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    let updatedPrice: number
    let updatedSurchargePrice: number

    let selectedCountryCode = "";

    if (countryCode) {
      selectedCountryCode = countryCode.split("-")[0];
    }

    if (selectedCountryCode === "CA" || selectedCountryCode === "US" || selectedCountryCode === "AE") {
      const stateOptions = selectedCountryCode === "CA" ? statesCanada : selectedCountryCode === "AE" ? statesAE : statesUs;
      const selectedState = stateOptions.find(state => state.value === countryCode);
      updatedPrice = selectedState
        ? multiYearSupport * (selectedState.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedState ? multiYearSupport * selectedState.surcharge: 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    } else {
      const selectedCountry = countries.find(
        country => country.value === countryCode || country.value === selectedCountryCode
      );

      updatedPrice = selectedCountry
        ? multiYearSupport * (selectedCountry.surcharge + multiYearPrice + entityTypeSurcharge + level2Surcharge)
        : 0;
      updatedSurchargePrice = selectedCountry
        ? multiYearSupport * selectedCountry.surcharge
        : 0;
      setSurchargePrice(updatedSurchargePrice);
      setPrice(updatedPrice);
    }

    console.log('---> price:', updatedPrice);

    setCompanyData(companyData => ({
      ...companyData,
      multi_year_support: multiYearSupport,
      price: updatedPrice,
      multi_year_price: multiYearPrice,
      entity_type_surcharge: entityTypeSurcharge,
      level2_surcharge: level2Surcharge,
      surcharge_price: updatedSurchargePrice,
      is_level_1_data_same: isLevel1DataSame,
      user_comment: isLevel1DataSame ? '' : userComments.trim()
    }));
  }, [
    countryCode, entityTypeSurcharge, isLevel1DataSame, level2Surcharge,
    multiYearPrice, multiYearSupport, userComments,
  ]);

  useEffect(() => {
    if (
      "company_name" in companyData
      && "lei_number" in companyData
      && "multi_year_support" in companyData
    ) {
      if (
        companyData["company_name"]
        && companyData["lei_number"]
        && companyData["multi_year_support"]
        && (isLevel1DataSame || userComments.trim())
      ) {
        setNextButtonEnabled(true);
        console.log("true");
      } else {
        setNextButtonEnabled(false);
      }
    } else {
      setNextButtonEnabled(false);
    }
    console.log("companyData", companyData)
  }, [companyData, isLevel1DataSame, userComments]);

  const checkCompany = async (row: any) => {
    const res = await CreateLeiService.orderSubmitStatus({
      order_type: 'Transfer',
      company_name: row['company_name'],
      company_number: row['company_number'],
      legal_jurisdiction: row['legal_jurisdiction']
    })

    const submitStatus = res.data.data['status'];
    if (submitStatus !== 'NO_ACTIVE_ORDERS') {
      const existingCompanyName = row["company_name"];
      const alertMessage = submitStatus === 'ALREADY_REGISTERED'
        ? `LEI already renews for company '${existingCompanyName}', please select another company.`
        : `There are active orders for company '${existingCompanyName}', please select another company.`;;      
      alert(alertMessage);
      setCompanyData(defaultCompanyData);
      return;
    } else {
      const data = {
        ...row,
        multi_year_support: multiYearSupport,
        is_level_1_data_same: isLevel1DataSame,
      };
      setCompanyData(data);
      setCompanyList([{
        ...row,
        id: row.id + "s",
      }]);
    }
  };

  const selectCompany = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
    let isRowSelected = false;
    if (companyData && companyData['company_name'] && companyData['lei_number']) {
      isRowSelected = companyData['company_name'] === row['company_name'] &&
        companyData['lei_number'] === row['lei_number'];
    }

    return (
      <div>
        {row['can_be_renewed'] && (
          <button 
            className={`${cl["button_select"]} ${isRowSelected ? cl['button_select_selected'] : ''}`}
            onClick={() => {
              if (!isRowSelected) {
                checkCompany(row);
              }
            }}
          >
            {isRowSelected ? 'Selected' : 'Renew'}
          </button>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: "lei_number",
      text: "LEI",
    },
    {
      dataField: "company_name",
      text: "Legal Name",
    },
    {
      dataField: "legal_jurisdiction",
      text: "Country",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "registration_status",
      text: "Status", 
      headerAlign: "center",
      align: "center",
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const status = row.registration_status;
        return (
          <div className={joinClasses(cl["item"], cl["status"], !(status === "ISSUED") ? cl["status-rejected"] : "") }>
            {status}
          </div>
        );
      }
    },
    {
      dataField: "next_renewal_date",
      text: "Next renewal date", 
      headerAlign: "center",
      align: "center",
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        const date = row.next_renewal_date;
        return (
          <div>
            {date.slice(0, 19).replace("T", " ")}
          </div>
        );
      }
    },
    {
      dataField: "selectCompany",
      text: "",
      formatter: selectCompany,
    }
  ];

  const handleNext = async () => {
    const data = companyData;
    setLoading(true);
    const preparedData = {
      ...data,
      order_type: 'Renew'
    };
    console.log("data", preparedData);
    await dispatch(fetchDataForm(preparedData))

    navigate('/relationship',
    {
      // state: {
      //   companyName: companyData["company_name"],
      //   companyNumber: companyData["company_number"],
      //   entityType: entityType,
      //   countryCode: countryCode,
      //   multiYearPrice: multiYearPrice,
      //   multiYearSupport: companyData["multi_year_support"],
      //   surchargePrice: surchargePrice,
      //   entityTypeSurcharge: entityTypeSurcharge,
      //   level2Surcharge: level2Surcharge,
      //   price: price,
      // },
    });
  };

  const tableBottomText = useMemo(() => {
    if (loading) {
      return "";
    }

    const renewableCompanies = companyList.filter(item => item.can_be_renewed);

    if (renewableCompanies.length === 0) {
      return "Sorry no LEI records available for Renewal.";
    }

    if (companyData && companyData.lei_number) {
      return '';
    }

    return 'Please select a LEI for Renewal.'
  }, [companyData, companyList, loading]);

  return (
    <Container>
      <Helmet> {/* don't forget: import { Helmet } from "react-helmet"; */}
        <title>LEI Renewal – Entity data</title>
        <meta 
          name="description" 
          content="LEI Renew Process – Entity data. LEIpapa is an official LEI Registration Agent of Ubisecure RapidLEI. Press Create LEI Application to Register, Renew or Transfer an LEI." 
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isAuth && (
        <RenewLeiPage>
          <div className={cl["container_big"]}>
            <div className={cl["title_wrap"]}>
              <Title>LEI Renewal – Entity data</Title>
            </div>
            <TabName className={cl["tab_name"]}>
              Please select an LEI for renewal
            </TabName>

            <div className={cl["card_body"]}>
              <div className={cl["table_wrap"]}>
                {loading ? <Loader /> : ""}
                {companyList.length > 0 && (
                  // <BootstrapTable
                  //   id={cl["table"]}
                  //   keyField="id"
                  //   data={companyList}
                  //   columns={columns}
                  // />
                  <DataTable value={companyList} className={cl["table"]}>
                    {columns.map((col, colIndex) => (
                        <Column 
                            key={col.dataField}
                            field={col.dataField}
                            header={col.text}
                            body={col.formatter ? (rowData) => col.formatter(rowData[col.dataField], rowData, colIndex, "") : undefined}
                            alignHeader={colIndex === 0 || colIndex === 1 || colIndex === 4 ? "left" : "center"}
                            style={{ padding: '10px' }}
                            // style={{ textAlign: col.align as 'left' | 'right' | 'center' }}
                            // headerStyle={{ textAlign: col.headerAlign as 'left' | 'right' | 'center' }}
                        />
                    ))}
                  </DataTable>
                )}
              </div>
              <div className={cl["wrap_l2"]}>
                <p style={{ lineHeight: "1.5" }}>
                  {tableBottomText}
                </p>
              </div>

              {companyData && companyData.lei_number && (
                <>
                  <div className={cl["wrap_l2"]}>
                    <p style={{ lineHeight: "1.5", fontWeight: 'bold' }}>
                      Did you have any changes in the structure of the Legal Entity (Level 1 data)?
                    </p>
                  </div>
                  <div className={cl['radio_wrapper']}>
                    <input
                      style={{ marginRight: '15px' }}
                      id="level1-same-no"
                      type="radio"
                      name="level1-same"
                      checked={isLevel1DataSame}
                      onChange={(e) => setIsLevel1DataSame(e.target.checked)}
                    />
                    <label htmlFor="level1-same-no">
                      No changes. Level 1 data remains the same.
                    </label>
                  </div>
                  <div className={cl['radio_wrapper']}>
                    <input
                      id="level1-same-yes"
                      style={{ marginRight: '15px' }}
                      type="radio"
                      name="level1-same"
                      checked={!isLevel1DataSame}
                      onChange={(e) => setIsLevel1DataSame(!e.target.checked)}
                    />
                    <label htmlFor="level1-same-yes">
                      Yes, Level 1 data has changed.
                    </label>
                  </div>
                  {!isLevel1DataSame && (
                    <div className={cl["input-comments-wrapper"]}>
                      <label htmlFor="userComment">
                        Please leave a comment on what's changed in the structure of the Legal Entity (Level 1 data).
                      </label>
                      <textarea 
                        className={cl["textarea_custom"]}
                        id="userComment"
                        placeholder="Max 400 characters"
                        maxLength={400}
                        value={userComments}
                        onChange={e => {
                          setUserComments(e.target.value || "")
                        }}
                      ></textarea>
                    </div>
                  )}

                  <div className={cl["input_wrap_years"]}>
                    <div>
                      <label htmlFor="multiyearsupport">Year to support <span className={cl["tooltip"]} data-tooltip="Specify for how many years you wish to register the LEI code.">ℹ️</span></label>
                      <select id="multiyearsupport" className={cl["custom-select"]}
                        value={multiYearSupport}
                        onChange={(event) => setMultiYearSupport(Number(event.target.value))}
                      >
                        <option value="0" disabled>
                          Years to Support
                        </option>
                        {["1", "2", "3", "4", "5"].map((variant) => (
                          <option key={variant} value={variant}>
                            {variant}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <Hr />
                  <div className={cl["wrap_l2_right"]}>
                    <div className={cl["price_text"]}>Order calculation</div>
                    <p>Years to support: {multiYearSupport}</p>
                    <p>Base price: €{multiYearPrice} x {multiYearSupport} = {multiYearPrice * multiYearSupport}</p>
                    {surchargePrice > 0 && (
                      <p>Jurisdictional surcharge: €{surchargePrice/multiYearSupport} x {multiYearSupport} = {surchargePrice}</p>
                    )}
                    {entityTypeSurcharge > 0 && (
                      <p>Entity type surcharge: €{entityTypeSurcharge} x {multiYearSupport} = {entityTypeSurcharge * multiYearSupport}</p>
                    )}
                    {level2Surcharge > 0 && (
                      <p>Level 2 surcharge: €{level2Surcharge} x {multiYearSupport} = {level2Surcharge * multiYearSupport}</p>
                    )}
                    <p>Total: €{price}</p>
                    {/* <p>Total: €{multiYearPrice * multiYearSupport + surchargePrice + entityTypeSurcharge + level2Surcharge}</p> */}
                    {/* <span className={cl["price_text"]}>Level 2 surcharge: €{level2Surcharge}</span> */}
                  </div>
                </>
              )}
            </div>
            <Hr/>
            <div className={cl["title_inner-button"]}>
              <NavLink to="/">
                <button className={cl["button_cancel"]}>
                  <CancelIcon />
                  <span className={cl["button_text"]}>Cancel</span>
                </button>
              </NavLink>

              <button className={cl["button_next"]} onClick={handleNext} disabled={!nextButtonEnabled}>
                <NextIcon />
                <span className={cl["button_text"]}>Next</span>
              </button>
            </div>
          </div>
        </RenewLeiPage>
      )}
    </Container>
  );
};
